<template>
<v-card flat>
  <Limited v-if="getSession.role === 'user'" />
  <v-row v-else>
    <v-col cols="12">
      <v-row>
        <v-col 
          cols="12"
          md="5"
          offset-md="4"
        >
        <v-card flat>
         <v-tabs background-color="transparent" class="mt-3 ml-5">
            <v-tab key="tab1" to="/new">New Entry</v-tab>
            <v-tab key="tab2" to="/existing">Existing </v-tab>
          </v-tabs>
          <v-divider class="mt-0"></v-divider>
          <router-view></router-view>
        </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'entryLayout',
  components: {
    Limited: () => import('@/modules/limited')
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['getDrawerStatus', 'getSession', 'isAuthenticated']),
  },
}
</script>
